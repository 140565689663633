import request from '@/api/request'

export function listUserBindDevice (data) {
  return request({
    url: 'gps/userBindDevice/list',
    method: 'post',
    data: data
  })
}

export function getUserBindDevice (bindId) {
  return request({
    url: 'gps/userBindDevice/getBind/' + bindId,
    method: 'get'
  })
}

export function createUserBindDevice (data) {
  const creatData = {
    userId: data.userId,
    deviceId: data.deviceId,
    mainControl: data.mainControl
  }
  return request({
    url: 'gps/userBindDevice/create',
    method: 'post',
    data: creatData
  })
}

export function deleteUserBindDevice (data) {
  const delData = {
    bindId: data.bindId
  }
  return request({
    url: 'gps/userBindDevice/delete',
    method: 'post',
    data: delData
  })
}

/**
 * 获取指定经销商账号下的绑定设备
 * @param {*} data 如果传入deviceImei,那么就是筛选设备
 * @returns
 */
export function listDealerDevice (data) {
  const req = {
    action: 'deviceAction',
    method: 'queryByAgent',
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
    'data': {
      parentId: data.parentId,
      deviceImei: data.deviceImei
    }
  }
  return returnDefaultRequest(req)
}

/**
 * 为经销商添加经销设备
 * @param {*} data
 * @returns
 */
export function dealerBindDevice (data) {
  const req = {
    action: 'deviceAction',
    method: 'bindDevice',
    'data': {
      deviceImei: data.imei,
      parentId: data.parentId
    }
  }
  return returnDefaultRequest(req)
}

/**
 * 解绑经销商下绑定的设备
 * @param {*} imei
 * @returns
 */
export function dealerUnbindDevice (imei) {
  const req = {
    action: 'deviceAction',
    method: 'unbindDevice',
    data: {
      deviceImei: imei
    }
  }
  return returnDefaultRequest(req)
}

function returnDefaultRequest (requestJson) {
  return request({
    url: '/MainServlet',
    method: 'post',
    data: requestJson
  })
}
