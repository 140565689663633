<template>
  <div class="rootView">
    <el-container>
      <el-header>
        <el-card>
          <el-row style="height:100%;" type="flex" justify="start" :align="'middle'">
            <el-col :span="8">
              <div>{{$t('common.dealer') + ': ' + dealerName}}</div>
            </el-col>
          </el-row>
        </el-card>
      </el-header>
      <el-main>
      <el-card>
        <el-row>
          <el-col :span="12">
            <el-form :inline="true" :model="searchFormData">
              <el-form-item :label="$t('common.filterDevice')">
                <el-input type="text" maxkength="20" v-model="searchFormData.imei" :placeholder="$t('device.msg.inputImei')"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmitSearchFormData">{{$t('common.filter')}}</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form :inline="true" :model="bindFormData">
              <el-form-item :label="$t('common.bindDevice')">
                <input type="text" ref="imeiInput" maxlength="20" class="el-input__inner" @keypress="onKekPressListener" v-model="bindFormData.imei" :placeholder="$t('device.msg.inputImei')"/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmitBindFormData">{{$t('common.bind')}}</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-table :data="deviceList" :border="true">
          <el-table-column prop="index" :label="$t('common.sn')" width="120"/>
          <el-table-column prop="deviceImei" :label="$t('device.deviceImei')"/>
          <el-table-column :label="$t('common.operation')">
            <template slot-scope="scope">
              <el-button @click="showDeleteDialog(scope.row)">{{$t('common.unbind')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.totalData">
        </el-pagination>
      </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { listDealerDevice, dealerBindDevice, dealerUnbindDevice } from '@/api/userBindDevice'
export default {
  name: 'soldDeviceManage',
  data () {
    return {
      dealerId: undefined,
      dealerName: '',
      deviceList: [],
      searchFormData: {
        imei: ''
      },
      bindFormData: {
        imei: ''
      },
      pagination: {
        pageSize: 10,
        currentPage: 1,
        totalData: 0
      }
    }
  },
  created () {
    this.dealerId = sessionStorage.getItem('dealerId')
    this.dealerName = sessionStorage.getItem('dealerName')
    this.$i18n.locale = window.sessionStorage.getItem('lang')
  },
  mounted () {
    this.queryDealerDevice()
  },
  methods: {
    onSubmitSearchFormData () {
      const imei = this.searchFormData.imei.trim()
      if (imei) {
        if (!this.checkImei(imei)) return this.$message.error(this.$t('device.msg.imeiRule'))
      }
      this.pagination.currentPage = 1
      const requestJson = {
        pageIndex: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        parentId: this.dealerId,
        deviceImei: imei
      }
      listDealerDevice(requestJson).then(res => {
        if (res.data.code === 0 && res.data.data) {
          this.deviceList = []
          if (res.data.totalcount) this.totalData = parseInt(res.data.totalcount)
          if (res.data.data[0]) {
            res.data.data[0].forEach(item => {
              item.index = this.deviceList.length + 1
              this.deviceList.push(item)
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    onSubmitBindFormData () {
      this.bindDeviceForDealer(this.bindFormData.imei)
    },
    queryDealerDevice () {
      if (!this.dealerId) return
      const filterImei = this.checkImei(this.searchFormData.imei)
      const requestJson = {
        pageIndex: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        parentId: this.dealerId,
        deviceImei: filterImei
      }
      listDealerDevice(requestJson).then(res => {
        if (res.data.code === 0 && res.data.data) {
          this.deviceList = []
          if (res.data.totalcount) {
            this.pagination.totalData = parseInt(res.data.totalcount)
          }
          if (res.data.data[0]) {
            res.data.data[0].forEach(item => {
              item.index = this.deviceList.length
              this.deviceList.push(item)
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    onKekPressListener (event) {
      if (event.keyCode === 13) {
        this.$refs['imeiInput'].select()
        this.bindDeviceForDealer(this.bindFormData.imei)
      }
    },
    bindDeviceForDealer (imei) {
      imei = this.checkImei(imei)
      if (!imei) return this.$message.error(this.$t('device.msg.imeiRule'))
      const requestJson = {
        imei: imei,
        parentId: this.dealerId
      }
      dealerBindDevice(requestJson).then(res => {
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.bindSuccees'))
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    unbindDevice (device) {
      dealerUnbindDevice(device.deviceImei).then(res => {
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.unbindSucceed'))
          this.deviceList.splice(device.index, 1)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleSizeChange (pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.pageIndex = 1
      this.queryDealerDevice()
    },
    handleCurrentChange (currentPage) {
      this.pagination.currentPage = currentPage
      this.queryDealerDevice()
    },
    showDeleteDialog (data) {
      this.$confirm(this.$t('device.msg.unbindTip'), this.$t('common.notice'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.unbindDevice(data)
      }).catch(() => {})
    },
    checkImei (imei) { // IMEI规范性检查，是否符合15-17位纯数字规范
      if (!imei) return
      imei = imei.trim()
      let regex = /^\d{15,17}$|^\d{6}$/g
      if (regex.test(imei)) {
        return imei
      }
    }
  }
}
</script>

<style scoped>
.rootView {
  height: 100%;
  width: 100%;
  background-color: #f4f5f9;
}

.header-form {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
</style>
